import mapboxgl from "mapbox-gl";
import { ref, getCurrentInstance } from "vue";

export default function riskPopup() {
    const instance = getCurrentInstance().proxy;

    let popupElement = ref(null);
    let isPopupShow = ref(false);
    let popupText = ref('');

    const registerLocationEvent = () => {
        instance.mapBox.geolocate.on('geolocate', () => {
            updateRiskPopupText(popupText.value);
        });
    }

    const updateRiskPopupText = (text = '') => {
        popupText.value = text;
        
        const currentPosition = instance.mapBox.currentPosition;
        if (!currentPosition) {
            return;
        }

        if (popupElement.value) {
            popupElement.value.remove();
        }

        init(currentPosition.coords.longitude, currentPosition.coords.latitude);
    }

    const setPopupDisplay = (displayStatus = true) => {
        const hideClass = "popup-hide";

        for (const child of popupElement.value.children) {
            if (displayStatus && child.classList.contains(hideClass)) {
                child.classList.remove(hideClass);
            } else if (!child.classList.contains(hideClass)) {
                child.classList.add(hideClass);
            }
        }

        isPopupShow.value = displayStatus;
    };

    const init = (long, lat) => {
        if (!popupText.value) {
            return;
        }

        const map = instance.mapBox.map;
        const TIP_INDEX = 0;
        const CONTENT_INDEX = 1;

        popupElement.value = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: "auto"
        })
        .setLngLat([long, lat])
        .setHTML('<div class="popup-container">' + popupText.value + '</div>')
        .addTo(map)
        .getElement();

        popupElement.value.children[TIP_INDEX].classList.add('popup-tips-warning');
        popupElement.value.children[CONTENT_INDEX].classList.add('popup-content-warning');

        if (!instance.zone.isPointInCurrentZone(long, lat)) {
            setPopupDisplay(false);
        }
    }

    return {
        registerLocationEvent,
        updateRiskPopupText,
        setPopupDisplay
    };
}