const congestions = [
    {
        id: "FaceCamera1",
        coordinates: [
            [139.757897, 35.656381], 
            [139.759127, 35.656133],
            [139.759926, 35.655990]
        ],
        popup: {
            text: "混雑",
            icon: 'congestion',
            containerClass: 'flex-column mb-auto',
            customClass: 'popup-center',
            center: [139.759127, 35.656133],
        },
        emergency: {
            text: "map.emergency.congestion",
        },
        bubble: {
            title: "map.bubble.congestion.title" ,
            subTitle: "map.bubble.congestion.content",
            imageUrl: require("@/assets/img/congestion.svg"),
            class: "bubble-container--lg"
        }
    },
]

export default congestions;