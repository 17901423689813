

import { ref } from "vue";
import zoneData from "@/constants/zoneData";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon, multiPolygon } from "@turf/helpers";

export default function zone() {
    let mapBox = ref(null);
    let zones = ref({
        liquefaction: [],
        storm: [],
        flood: [],
        tsunami: [],
    });
    const ZONE_TYPE = {
        LIQUEFACTION: 'liquefaction',
        STORM: 'storm',
        FLOOD: 'flood',
        TSUNAMI: 'tsunami',
    }
    let currentZone = ref(null);

    const initZone = async (zoneType) => {
        await zoneData[zoneType].forEach((zone) => {
            mapBox.value.zone.addZone(zone.slug, zone.coordinate, zone.color, zone.opacity, zone.type);
            zones.value[zoneType].push({
                slug: zone.slug,
                coordinate: zone.coordinate,
                type: zone.type
            });
        })
    }

    const toggleZone = async (zoneType = ZONE_TYPE.LIQUEFACTION, toggle = false) => {
        await zones.value[zoneType].forEach((zone) => {
            mapBox.value.zone.toggleLayer('zone-' + zone.slug, toggle);
        })
    }

    const initLiquefactionZone = () => {
        initZone(ZONE_TYPE.LIQUEFACTION);
    }

    const initStormZone = () => {
        initZone(ZONE_TYPE.STORM);
    }

    const initFloodZone = () => {
        initZone(ZONE_TYPE.FLOOD);
    }

    const initTsunamiZone = () => {
        initZone(ZONE_TYPE.TSUNAMI);
    }

    const toggleLiquefactionZone = async (toggle = false) => {
        toggleZone(ZONE_TYPE.FLOOD, !toggle);
        toggleZone(ZONE_TYPE.STORM, !toggle);
        toggleZone(ZONE_TYPE.TSUNAMI, !toggle);
        toggleZone(ZONE_TYPE.LIQUEFACTION, toggle);
        currentZone.value = ZONE_TYPE.LIQUEFACTION;
    }

    const toggleStormZone = async (toggle = false) => {
        toggleZone(ZONE_TYPE.FLOOD, !toggle);
        toggleZone(ZONE_TYPE.LIQUEFACTION, !toggle);
        toggleZone(ZONE_TYPE.TSUNAMI, !toggle);
        toggleZone(ZONE_TYPE.STORM, toggle);
        currentZone.value = ZONE_TYPE.STORM;
    }

    const toggleFloodZone = async (toggle = false) => {
        toggleZone(ZONE_TYPE.LIQUEFACTION, !toggle);
        toggleZone(ZONE_TYPE.STORM, !toggle);
        toggleZone(ZONE_TYPE.TSUNAMI, !toggle);
        toggleZone(ZONE_TYPE.FLOOD, toggle);
        currentZone.value = ZONE_TYPE.FLOOD;
    }

    const toggleTsunamiZone = (toggle = false) => {
        toggleZone(ZONE_TYPE.LIQUEFACTION, !toggle);
        toggleZone(ZONE_TYPE.STORM, !toggle);
        toggleZone(ZONE_TYPE.FLOOD, !toggle);
        toggleZone(ZONE_TYPE.TSUNAMI, toggle);
        currentZone.value = ZONE_TYPE.TSUNAMI;
    }

    const isPointInCurrentZone = (longitude, latitude) => {
        let flag = false;
        const pt = point([longitude, latitude]);

        zones.value[currentZone.value].some((zone) => {
            const poly = (zone.type === "MultiPolygon") 
                ? multiPolygon([zone.coordinate]) 
                : polygon([zone.coordinate]);
            flag = false;

            if (booleanPointInPolygon(pt, poly)) {
                flag = true;
                return true;
            }

            return false;
        });

        return flag;
    }

    const init = (mapBoxInstance) => {
        mapBox.value = mapBoxInstance;
    }

    return {
        init,
        initLiquefactionZone,
        initStormZone,
        initFloodZone,
        initTsunamiZone,
        toggleTsunamiZone,
        toggleStormZone,
        toggleLiquefactionZone,
        toggleFloodZone,
        isPointInCurrentZone
    };
}