const bubbleData = {
    storm: {
        title: "map.bubble.storm._",
        level: [
            {
                min: '5.0',
                max: '10',
            },
            {
                min: '3.0',
                max: '5.0',
            },
            {
                min: '1.0',
                max: '3.0',
            },
            {
                min: '0.5',
                max: '1.0',
            },
            {
                min: '0.1',
                max: '0.5',
            }
        ]
    },
    flood: {
        title: "map.bubble.flood._",
        level: [
            {
                max: '5.0',
            },
            {
                min: '3.0',
                max: '5.0',
            },
            {
                min: '2.0',
                max: '3.0',
            },
            {
                min: '1.0',
                max: '2.0',
            },
            {
                min: '0.5',
                max: '1.0',
            },
            {
                min: '0.1',
                max: '0.5',
            }
        ]
    },
    tsunami: {
        title: "map.bubble.tsunami._",
        level: [
            {
                max: '1.50',
            },
            {
                min: '0.80',
                max: '1.50',
            },
            {
                min: '0.50',
                max: '0.80',
            },
            {
                min: '0.15',
                max: '0.50',
            },
            {
                min: '0.00',
                max: '0.15',
            },
        ]
    },
    liquefaction: {
        title: "map.bubble.liquefaction._",
        level: [
            { text: 'map.bubble.liquefaction.high'},
            { text: 'map.bubble.liquefaction.normal'},
            { text: 'map.bubble.liquefaction.low'}
        ],
        child: {
            title: 'map.bubble.liquefaction.question',
            imageUrl:
                require("@/assets/img/liquefaction_risk.svg"),
            imageText: 'map.bubble.liquefaction.answer',
            size: "lg",
        }
    },
    risk: {
        title: "map.bubble.risk._",
        content: "map.bubble.risk.content",
        class: "bubble-container--sm"
    }
}

export default bubbleData;