const COLOR = {
    LIQUEFACTION: {
        HIGH_POSIBILITY: "#F39B99",
        LOW_POSIBILITY: "#FEEE96"
    },
    FLOOD: {
        LEVEL_5: "#2c68b2",
        LEVEL_4: "#28b4d2",
        LEVEL_1: "#efea3c"
    },
    STORM: {
        LEVEL_4: "#FEBABC",
        LEVEL_3: "#FBD9BE"
    },
    TSUNAMI: {
        LEVEL_4: "#6FACDD",
        LEVEL_3: "#99D1C6",
        LEVEL_2: "#ABD38A",
        LEVEL_1: "#FFF35D"
    }
}

const zoneData = {
    liquefaction: [
        {
            slug: "liquefaction_low",
            color: COLOR.LIQUEFACTION.LOW_POSIBILITY,
            opacity: 0.55,
            type: 'MultiPolygon',
            coordinate: [
                [
                    [139.7637616,35.6542639],
                    [139.763173,35.654238],
                    [139.763173,35.653741],
                    [139.761982,35.653741],
                    [139.762014,35.654508],
                    [139.761338,35.654526],
                    [139.76136,35.656226],
                    [139.759364,35.656217],
                    [139.759343,35.656557],
                    [139.758109,35.656566],
                    [139.75812,35.657603],
                    [139.759858,35.657594],
                    [139.759879,35.657987],
                    [139.761263,35.657987],
                    [139.758109,35.659704],
                    [139.756328,35.652852],
                    [139.759107,35.652643],
                    [139.759783,35.652869],
                    [139.762476,35.651867],
                    [139.7637616,35.6542639],
                ],
                [
                    [139.75810906425855, 35.65656567446822],
                    [139.7581197930935, 35.657603044807686],
                    [139.75985786435726, 35.657594327466015],
                    [139.7598793220272, 35.657986606899065],
                    [139.76126334173722, 35.657986606899065],
                    [139.76377388911823, 35.65667900129537],
                    [139.76376316028328, 35.65426423333978],
                    [139.76317307436037, 35.65423808021859],
                    [139.76317307436037, 35.65374116928895],
                    [139.76198217367963, 35.65374116928895],
                    [139.7620143601845, 35.65450832872454],
                    [139.76133844358193, 35.65452576408063],
                    [139.7613599012519, 35.65622569302185],
                    [139.75936433794902, 35.65621697552986],
                    [139.75934288027912, 35.65655695701332],
                    [139.75810906425855, 35.65656567446822],
                ],
                [
                    [139.75801250475598, 35.655894427665956],
                    [139.75879570970815, 35.655894427665956],
                    [139.75879570970815, 35.655458550091666],
                    [139.75801250475598, 35.655458550091666],
                    [139.75801250475598, 35.655894427665956],
                ],
                [
                    [139.75733658815338, 35.655458550091666],
                    [139.75801250475598, 35.655458550091666],
                    [139.75801250475598, 35.65499654737441],
                    [139.75733658815338, 35.65499654737441],
                    [139.75733658815338, 35.655458550091666],
                ],
                [
                    [139.75801250475598, 35.65459550547647],
                    [139.75879570970815, 35.65459550547647],
                    [139.75879570970815, 35.65416833852981],
                    [139.75801250475598, 35.65416833852981],
                    [139.75801250475598, 35.65459550547647],
                ]
            ]
        },
        {
            slug: "liquefaction_low_2",
            color: COLOR.LIQUEFACTION.LOW_POSIBILITY,
            opacity: 0.55,
            type: 'Polygon',
            coordinate: [
                [139.76377388911823, 35.65667900129537],
                [139.76469656893698, 35.656007755445636],
                [139.76376316028328, 35.65426423333978],
                [139.76377388911823, 35.65667900129537],
            ]
        },
        {
            slug: "liquefaction_high_1",
            color: COLOR.LIQUEFACTION.HIGH_POSIBILITY,
            opacity: 0.55,
            coordinate: [
                [139.75810906425855, 35.65656567446822],
                [139.7581197930935, 35.657603044807686],
                [139.75985786435726, 35.657594327466015],
                [139.7598793220272, 35.657986606899065],
                [139.76126334173722, 35.657986606899065],
                [139.76377388911823, 35.65667900129537],
                [139.76376316028328, 35.65426423333978],
                [139.76317307436037, 35.65423808021859],
                [139.76317307436037, 35.65374116928895],
                [139.76198217367963, 35.65374116928895],
                [139.7620143601845, 35.65450832872454],
                [139.76133844358193, 35.65452576408063],
                [139.7613599012519, 35.65622569302185],
                [139.75936433794902, 35.65621697552986],
                [139.75934288027912, 35.65655695701332],
                [139.75810906425855, 35.65656567446822],
            ]
        },
        {
            slug: "liquefaction_high_2",
            color: COLOR.LIQUEFACTION.HIGH_POSIBILITY,
            opacity: 0.55,
            coordinate: [
                [139.75801250475598, 35.655894427665956],
                [139.75879570970815, 35.655894427665956],
                [139.75879570970815, 35.655458550091666],
                [139.75801250475598, 35.655458550091666],
                [139.75801250475598, 35.655894427665956],
            ]
        },
        {
            slug: "liquefaction_high_3",
            color: COLOR.LIQUEFACTION.HIGH_POSIBILITY,
            opacity: 0.55,
            coordinate: [
                [139.75733658815338, 35.655458550091666],
                [139.75801250475598, 35.655458550091666],
                [139.75801250475598, 35.65499654737441],
                [139.75733658815338, 35.65499654737441],
                [139.75733658815338, 35.655458550091666],
            ]
        },
        {
            slug: "liquefaction_high_4",
            color: COLOR.LIQUEFACTION.HIGH_POSIBILITY,
            opacity: 0.55,
            coordinate: [
                [139.75801250475598, 35.65459550547647],
                [139.75879570970815, 35.65459550547647],
                [139.75879570970815, 35.65416833852981],
                [139.75801250475598, 35.65416833852981],
                [139.75801250475598, 35.65459550547647],
            ]
        },
    ],
    storm: [
        {
            slug: "storm_low",
            color: COLOR.STORM.LEVEL_3,
            opacity: 0.55,
            type: 'MultiPolygon',
            coordinate: [
                [
                    [139.75861338864541, 35.65957312676127],
                    [139.76469656893698, 35.656007755445636],
                    [139.7624756998913, 35.65186682828221],
                    [139.7598093826163, 35.65289241394986],
                    [139.75920856779885, 35.652944721064934],
                    [139.75680530852918, 35.65303189951391],
                    [139.75769, 35.65625],
                    [139.75663922406073, 35.65638256927531],
                    [139.7569610891527, 35.65723687719861], 
                    [139.75733663097265, 35.65668766285635], 
                    [139.75787309882895, 35.65664411859874],
                    [139.7580018448657, 35.65751585842755],
                    [139.75861338864541, 35.65957312676127],
                ],
                [
                    [139.7580018448657, 35.65751585842755], 
                    [139.7583129811212, 35.65741125015051], 
                    [139.75875218427282, 35.65612007516401],
                    [139.75769, 35.65625],
                    [139.75663922406073, 35.65638256927531], 
                    [139.7569610891527, 35.65723687719861], 
                    [139.75733663097265, 35.65668766285635], 
                    [139.75787309882895, 35.65664411859874], 
                    [139.7580018448657, 35.65751585842755], 
                ],
                [
                    [139.7594928867907, 35.65586512770879], 
                    [139.75956798864547, 35.65405186069884], 
                    [139.758420003151, 35.65405186069884], 
                    [139.75844145609938, 35.654592377655234],
                    [139.75790501427954, 35.65467955430582], 
                    [139.75789428544311, 35.65538568166883], 
                    [139.75870526188135, 35.65580553621727], 
                    [139.7594928867907, 35.65586512770879], 
                ]
            ]
        },
        {
            slug: "storm_high_1",
            color: COLOR.STORM.LEVEL_4,
            opacity: 0.55,
            coordinate: [
                [139.7580018448657, 35.65751585842755], 
                [139.7583129811212, 35.65741125015051], 
                [139.75875218427282, 35.65612007516401],
                [139.75663922406073, 35.65638256927531], 
                [139.7569610891527, 35.65723687719861], 
                [139.75733663097265, 35.65668766285635], 
                [139.75787309882895, 35.65664411859874], 
                [139.7580018448657, 35.65751585842755], 
            ]
        },
        {
            slug: "storm_high_2",
            color: COLOR.STORM.LEVEL_4,
            opacity: 0.55,
            coordinate: [
                [139.7594928867907, 35.65586512770879], 
                [139.75956798864547, 35.65405186069884], 
                [139.758420003151, 35.65405186069884], 
                [139.75844145609938, 35.654592377655234],
                [139.75790501427954, 35.65467955430582], 
                [139.75789428544311, 35.65538568166883], 
                [139.75870526188135, 35.65580553621727], 
                [139.7594928867907, 35.65586512770879], 
            ]
        },
    ],
    flood: [
        {
            slug: "flood_low_1",
            color: COLOR.FLOOD.LEVEL_1,
            opacity: 0.55,
            coordinate: [
                [139.7572684680747, 35.653598041324564],
                [139.75832408840964, 35.6528851027152],
                [139.75829666969966, 35.65246179240498],
                [139.75702169968469, 35.65257875995185],
                [139.7572684680747, 35.653598041324564],
            ]
        },
        {
            slug: "flood_low_2",
            color: COLOR.FLOOD.LEVEL_1,
            opacity: 0.55,
            type: "MultiPolygon",
            coordinate: [
                [
                    [139.75861338864541, 35.65957312676127],
                    [139.7627224579712, 35.65724873195156], 
                    [139.76246880808026, 35.65696950561132],
                    [139.76411344125154, 35.65595896399899],
                    [139.76318066423323, 35.65519440101614],
                    [139.76371251077876, 35.65484203474276],
                    [139.76228879851445, 35.652229157053455],
                    [139.7598423044586, 35.652847479365754],
                    [139.75910684571338, 35.65264272360355],
                    [139.75910590154947, 35.6530469371451],
                    [139.75969502387684, 35.65352563378337],
                    [139.75737005556522, 35.65394041147116],
                    [139.75781609388864, 35.656011410206645],
                    [139.75710607370033, 35.65618152557313],
                    [139.7572335132213, 35.65685458716576],
                    [139.7580709729306, 35.656743643337656],
                    [139.7583988119175,35.658980359983815],
                    [139.75861338864541, 35.65957312676127],
                ],
                [
                    [139.7583988119175, 35.658980359983815],
                    [139.76109418931645, 35.65767421783078],
                    [139.7613478392074, 35.65711576714939],
                    [139.76075871688002,35.656936264315334],
                    [139.76072598786183, 35.65595896402963],
                    [139.761192376371, 35.65575951352233],
                    [139.76157694233467, 35.656663685166166],
                    [139.76222334044385,35.656078634094904],
                    [139.76246880808026,35.65696950561132],
                    [139.76411344125154,35.65595896399899],
                    [139.76327066903326,35.65595231565679],
                    [139.7626324531786,35.65521434623042],
                    [139.76313975296048,35.65482208943553],
                    [139.7628921089844, 35.653962695612385],
                    [139.7621906114599,35.65406416404112],
                    [139.76232970978717,35.65348574247539],
                    [139.7619860550962,35.65250175133728],
                    [139.75969502387684, 35.65352563378337],
                    [139.75737005556522,35.65394041147116],
                    [139.75781609388864,35.656011410206645],
                    [139.75710607370033,35.65618152557313],
                    [139.7572335132213,35.65685458716576],
                    [139.7580709729306,35.656743643337656],
                    [139.7583988119175,35.658980359983815],
                ],
            ]
        },
        {
            slug: "flood_high",
            color: COLOR.FLOOD.LEVEL_4,
            opacity: 0.55,
            type: "MultiPolygon",
            coordinate: [
                [
                    [139.7583988119175, 35.658980359983815],
                    [139.76109418931645, 35.65767421783078],
                    [139.7613478392074, 35.65711576714939],
                    [139.76075871688002,35.656936264315334],
                    [139.76072598786183, 35.65595896402963],
                    [139.761192376371, 35.65575951352233],
                    [139.76157694233467, 35.656663685166166],
                    [139.76222334044385,35.656078634094904],
                    [139.76246880808026,35.65696950561132],
                    [139.76411344125154,35.65595896399899],
                    [139.76327066903326,35.65595231565679],
                    [139.7626324531786,35.65521434623042],
                    [139.76313975296048,35.65482208943553],
                    [139.7628921089844, 35.653962695612385],
                    [139.7621906114599,35.65406416404112],
                    [139.76232970978717,35.65348574247539],
                    [139.7619860550962,35.65250175133728],
                    [139.75969502387684, 35.65352563378337],
                    [139.75737005556522,35.65394041147116],
                    [139.75781609388864,35.656011410206645],
                    [139.75710607370033,35.65618152557313],
                    [139.7572335132213,35.65685458716576],
                    [139.7580709729306,35.656743643337656],
                    [139.7583988119175,35.658980359983815],
                ],
                [
                    [139.75850851700767, 35.65575296288511],
                    [139.75915761160968, 35.65559168817419],
                    [139.7595009343744, 35.654497626807576],
                    [139.75913615393688, 35.65400943522661],
                    [139.758420003151, 35.65405186069884],
                    [139.75844145609938, 35.654592377655234],
                    [139.75790501427954, 35.65467955430582],
                    [139.7580471770426, 35.65530400815158],
                    [139.75850851700767, 35.65575296288511],
                ]
            ]
        },
        {
            slug: "flood_highest",
            color: COLOR.FLOOD.LEVEL_5,
            opacity: 0.55,
            coordinate: [
                [139.75850851700767, 35.65575296288511],
                [139.75915761160968, 35.65559168817419],
                [139.7595009343744, 35.654497626807576],
                [139.75913615393688, 35.65400943522661],
                [139.758420003151, 35.65405186069884],
                [139.75844145609938, 35.654592377655234],
                [139.75790501427954, 35.65467955430582],
                [139.7580471770426, 35.65530400815158],
                [139.75850851700767, 35.65575296288511],
            ]
        },
    ],
    tsunami: [
        {
            slug: "tsunami_high_1",
            color: COLOR.TSUNAMI.LEVEL_3,
            opacity: 0.55,
            coordinate: [
                [139.75648240076816,35.653549509069926],
                [139.7569383762728,35.65356258574432],
                [139.75738873342655,35.65292163753834],
                [139.7565789602717,35.652390035396245],
                [139.75610152710377,35.652974133259136],
                [139.75648240076816,35.653549509069926]
            ]
        },
        {
            slug: "tsunami_high_2",
            color: COLOR.TSUNAMI.LEVEL_3,
            opacity: 0.55,
            coordinate: [
                [139.76352562746038,35.65635639746589],
                [139.7645770533299,35.65572001908963],
                [139.76252784558147,35.65184061657151],
                [139.7612833006747,35.65243343634264],
                [139.7603498919946,35.65259035848676],
                [139.76027479014678,35.65290420185014],
                [139.76233472654422,35.65223292426539],
                [139.762967727833,35.65337496458303],
                [139.7641157134076,35.65567643134408],
                [139.7635363562958,35.65605128517856],
                [139.76352562746038,35.65635639746589]
            ],
        },
        {
            slug: "tsunami_low_1",
            color: COLOR.TSUNAMI.LEVEL_2,
            opacity: 0.55,
            coordinate: [
                [139.75918070303229,35.65915920586736],
                [139.75973860247325,35.65909818564477],
                [139.76039306143286,35.65759009961371],
                [139.7598888061689,35.657624968976535],
                [139.75918070303229,35.65915920586736]
            ]
        },
        {
            slug: "tsunami_low_2",
            color: COLOR.TSUNAMI.LEVEL_2,
            opacity: 0.55,
            type: "MultiPolygon",
            coordinate: [
                [
                    [139.7602214000664,35.656918861412166],
                    [139.7603928074458,35.6560600027085],
                    [139.76106872407624,35.655693866465015],
                    [139.76262440528652,35.6568271398512],
                    [139.76352562746038,35.65635639746589],
                    [139.7635363562958,35.65605128517856],
                    [139.7641157134076,35.65567643134408],
                    [139.762967727833,35.65337496458303],
                    [139.76233472654422,35.65223292426539],
                    [139.76027479014678,35.65290420185014],
                    [139.7576469118469,35.65253519042899],
                    [139.75739173569895,35.65191832881847],
                    [139.75648776517076,35.65190183092185],
                    [139.7565789602717,35.652390035396245],
                    [139.75738873342655,35.65292163753834],
                    [139.7569383762728, 35.65356258574432],
                    [139.75729242784112,35.65438205306898],
                    [139.75632683265482,35.65451281835354],
                    [139.7565950535399,35.654817936518306],
                    [139.7577108524724,35.65493126585995],
                    [139.7583438537612,35.6556635436605],
                    [139.75814000588852,35.656265052545365],
                    [139.7570671223482,35.656413249691006],
                    [139.7570993088544,35.6566660559518],
                    [139.75817219239474,35.65645683703444],
                    [139.75947038158816,35.656221465097104]
                ],
                [
                    [139.75817219239474,35.65645683703444],
                    [139.75947038158816,35.656221465097104],
                    [139.75908388944788,35.6555631031084],
                    [139.75991000977393,35.65581591206028],
                    [139.75994219628015,35.65365393380425],
                    [139.75954522937022,35.65304368741572],
                    [139.757560394793,35.653095994391535],
                    [139.75767841198243,35.65361034489095],
                    [139.7569383762728,35.65356258574432],
                    [139.75729242784112,35.65438205306898],
                    [139.75632683265482,35.65451281835354],
                    [139.7565950535399,35.654817936518306],
                    [139.7577108524724,35.65493126585995],
                    [139.7583438537612,35.6556635436605],
                    [139.75814000588852,35.656265052545365],
                    [139.75817219239474,35.65645683703444]
                ],
                [
                    [139.7635363562958,35.65605128517856],
                    [139.7641157134076,35.65567643134408],
                    [139.762967727833,35.65337496458303],
                    [139.7619806751623,35.6537498292273],
                    [139.76266732062814,35.65524055258003],
                    [139.7635363562958,35.65605128517856]
                ]
            ]
        },
        {
            slug: "tsunami_lowest_1",
            color: COLOR.TSUNAMI.LEVEL_1,
            opacity: 0.55,
            coordinate: [
                [139.76037134977503, 35.655833347136664],
                [139.76170172536501, 35.65507491804582],
                [139.76109018174702, 35.65429032864874],
                [139.7603820786104, 35.65467390665027],
                [139.76037134977503, 35.655833347136664],
            ]
        },
        {
            slug: "tsunami_lowest_2",
            color: COLOR.TSUNAMI.LEVEL_1,
            opacity: 0.55,
            coordinate: [
                [139.7635363562958,35.65605128517856],
                [139.7641157134076,35.65567643134408],
                [139.762967727833,35.65337496458303],
                [139.7619806751623,35.6537498292273],
                [139.76266732062814,35.65524055258003],
                [139.7635363562958,35.65605128517856]
            ]
        },
        {
            slug: "tsunami_lowest_3",
            color: COLOR.TSUNAMI.LEVEL_1,
            opacity: 0.55,
            coordinate: [
                [139.75817219239474,35.65645683703444],
                [139.75947038158816,35.656221465097104],
                [139.75908388944788,35.6555631031084],
                [139.75991000977393,35.65581591206028],
                [139.75994219628015,35.65365393380425],
                [139.75954522937022,35.65304368741572],
                [139.757560394793,35.653095994391535],
                [139.75767841198243,35.65361034489095],
                [139.7569383762728,35.65356258574432],
                [139.75729242784112,35.65438205306898],
                [139.75632683265482,35.65451281835354],
                [139.7565950535399,35.654817936518306],
                [139.7577108524724,35.65493126585995],
                [139.7583438537612,35.6556635436605],
                [139.75814000588852,35.656265052545365],
                [139.75817219239474,35.65645683703444]
            ]
        },
    ]
}

export default zoneData;