<template>
    <div>
    <emergency-popup
        v-if="emergencyData"
        :text="emergencyData.text"
        :color="emergencyData.color"
    ></emergency-popup>
    <map-box ref="mapBox" />
    <bubble-popup
        ref="bubblePopup"
        :title="bubbleContent.title"
        :subtitle="bubbleContent.subTitle"
        :size="bubbleContent.size"
        :class="bubbleContent.class"
        @on-close-event="closeBubbleEvent"
    >
        <template #title>
            <div class="flex width-100" v-if="bubbleContent.title === 'map.bubble.liquefaction._'">
                <div>{{ $t(bubbleContent.title) }} </div>
                <img @click="onQuestionClick" class="icon-questionmark" height="22" width="22" src="@assets/img/icons/questionmark.svg" />
            </div>
            <div class="flex width-100" @click="onBackClick" v-else-if="bubbleContent.title === 'map.bubble.liquefaction.question'">
                <img class="icon-back-arrow" src="@assets/img/icons/back-arrow.svg" />
                <div>{{ $t(bubbleContent.title) }} </div>
            </div>
        </template>

        <template
            #content
        >
            <div class="bubble-storm" v-if="bubbleContent.title === 'map.bubble.storm._'">
                <div class="bubble-grid">
                    <div
                        class="bubble-grid-row"
                        v-for="(data, index) in bubbleContent.level"
                        :key="index"
                    >
                        <div class="bubble-box"></div>
                        <div class="bubble-text">
                            {{ `${data.min}m〜${data.max}m` }}
                        </div>
                    </div>

                    <div class="bubble-icon-building">
                        <img src="@assets/img/icons/half_building.svg" />
                    </div>

                    <div class="bubble-icon-family">
                        <img src="@assets/img/icons/family.svg" />
                    </div>
                </div>
            </div>

            <div class="bubble-flood" v-else-if="bubbleContent.title === 'map.bubble.flood._'">
                <div class="bubble-grid">
                    <div
                        class="bubble-grid-row"
                        v-for="(data, index) in bubbleContent.level"
                        :key="index"
                    >
                        <div class="bubble-box"></div>
                        <div class="bubble-text" v-if="index === 0">
                            {{ `${data.max}m 以上` }}
                        </div>
                        <div class="bubble-text" v-else-if="index === 1">
                            {{ `${data.min}m〜${data.max}m 未満` }}
                        </div>
                        <div class="bubble-text" v-else>
                            {{ `${data.min}m〜${data.max}m` }}
                        </div>
                    </div>

                    <div class="bubble-icon-building">
                        <img src="@assets/img/icons/half_building.svg" />
                    </div>

                    <div class="bubble-icon-family">
                        <img src="@assets/img/icons/family.svg" />
                    </div>
                </div>
            </div>

            <div class="bubble-tsunami" v-else-if="bubbleContent.title === 'map.bubble.tsunami._'">
                <div class="bubble-grid">
                    <div
                        class="bubble-grid-row"
                        v-for="(data, index) in bubbleContent.level"
                        :key="index"
                    >
                        <div class="bubble-box"></div>
                        <div class="bubble-text" v-if="index === 0">
                            {{ `${data.max}m 以上` }}
                        </div>
                        <div class="bubble-text" v-else>
                            {{ `${data.min}m〜${data.max}m 未満` }}
                        </div>
                    </div>

                    <div class="bubble-icon-family">
                        <img src="@assets/img/icons/family.svg" />
                    </div>
                </div>
            </div>

            <div class="bubble-liquefaction" v-else-if="bubbleContent.title === 'map.bubble.liquefaction._'">
                <div class="bubble-grid">
                    <div
                        class="bubble-grid-row"
                        v-for="(data, index) in bubbleContent.level"
                        :key="index"
                    >
                        <div class="bubble-box"></div>
                        <div class="bubble-text">
                            {{ $t(data.text) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="bubble-body-container" v-else-if="bubbleContent.title === 'map.bubble.risk._'">
                <div class="flex flex-column width-100">
                    <div>{{ $t(bubbleContent.content) }}</div>
                    <div class="bubble-button-container">
                        <button @click="onFloodClick" :class="riskButtonActive === 'flood' ? 'btn-active' : ''">
                            <img class="btn-icon" :src="floodImageSrc"/>
                            <div class="btn-text">{{ $t(floodButtonName) }}</div>
                        </button>
                        <button @click="onTsunamiClick" :class="riskButtonActive === 'tsunami' ? 'btn-active' : ''">
                            <img class="btn-icon" src="@assets/img/icons/tsunami.svg"/>
                            <div class="btn-text">{{ $t(tsunamiButtonName) }}</div>
                        </button>
                    </div>
                </div>
            </div>

            <div class="bubble-body-container" v-else>
                <div class="bubble-image">
                    <img :src="bubbleContent.imageUrl" />
                </div>
            </div>
        </template>
    </bubble-popup>
    <carousel-popup
        ref="carouselPopup"
        :data="carousel"
        :selected="selectedMarker"
        @on-select="onCarouselSelect"
    ></carousel-popup>
    <loader v-if="isShowLoader" />
    </div>
</template>

<script>
import { onMounted, ref, computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import bubbleData from "@/constants/bubbleData";
import * as vueI18n from "vue-i18n";
import { useStore } from "vuex";
import getZones from "@/composables/mapViews/zone";
import getCongestions from "@/composables/mapViews/congestion";
import getWaterLevels from "@/composables/mapViews/waterLevel";
import getShelters from "@/composables/mapViews/shelter";
import useMetaTag from "@/composables/dom/useMetaTag";
import useRiskPopup from "@/composables/mapViews/riskPopup";
import useQuery from "@/composables/dom/useQuery";
import query from "@/constants/query";

export default {
    name: "Map",
    setup() {
        const store = useStore();
        const mapBox = ref(null);
        const bubblePopup = ref(null);
        const route = useRoute();
        const carouselPopup = ref(null);
        const bubbleSample = bubbleData;
        const zone = getZones();
        const congestion = getCongestions();
        const waterLevel = getWaterLevels();
        const shelter = getShelters();
        const { t } = vueI18n.useI18n();
        const { setTitle } = useMetaTag();
        const riskPopup = useRiskPopup();

        const { createQueryIfNotExist, checkIfQueryExisted, removeQuery, getQuery } = useQuery();

        const blueBanner = [
            {
                text: t('map.emergency.no_congestion'),
                status: false,
            },
            {
                text: t('map.emergency.no_flood'),
                status: false,
            },
            {
                text: t('map.emergency.no_flood_congestion'),
                status: false,
            },
        ];

        const CONGESTION_BUTTON = 0;
        const WATER_LEVEL_BUTTON = 1;

        let bubbleContent = ref({});
        let carousel = ref([]);
        let emergencyData = ref(null);
        let listControlData = ref(null);
        let selectedMarker = ref(null);
        let closeBubbleEvent = ref(null);
        let floodButtonName = ref(null);
        let tsunamiButtonName = ref(null);
        let riskButtonActive = ref(null);

        const updateCarousel = (carousels) => {
            carousel.value = carousels;
        }

        const updateBubble = (bubble) => {
            bubbleContent.value = bubble;
        }

        const updateSelectedMarker = (id) => {
            selectedMarker.value = id;
        }

        const onQuestionClick = () => {
            bubbleContent.value = bubbleSample.liquefaction.child;
            listControlData.value = bubbleContent.value;
        }

        const onBackClick = () => {
            bubbleContent.value = bubbleSample.liquefaction;
            listControlData.value = bubbleContent.value;
        }

        const onTsunamiClick = () => {
            if (isRiskMode()) {
                bubbleContent.value = bubbleSample.tsunami;
                zone.toggleTsunamiZone(true);
                riskPopup.updateRiskPopupText(t('map.risk_popup.tsunami'));
            } else {
                zone.toggleStormZone(true);
                bubbleContent.value = bubbleSample.storm;
                riskPopup.updateRiskPopupText(t('map.risk_popup.storm'));
            }

            listControlData.value = bubbleContent.value;
            riskButtonActive.value = "tsunami";
            mapBox.value.control.listControl.setActiveButton(true);
        }

        const onFloodClick = () => {
            if (isRiskMode()) {
                bubbleContent.value = bubbleSample.liquefaction;
                zone.toggleLiquefactionZone(true);
                riskPopup.updateRiskPopupText(t('map.risk_popup.liquefaction'));
            } else {
                zone.toggleFloodZone(true);
                bubbleContent.value = bubbleSample.flood;
                riskPopup.updateRiskPopupText(t('map.risk_popup.flood'));
            }

            listControlData.value = bubbleContent.value;
            riskButtonActive.value = "flood";
            mapBox.value.control.listControl.setActiveButton(true);
        }

        const isWaterLevelMode = () => {
            return route.query.mode === query.mode.waterlevel;
        }

        const isRiskMode = () => {
            return route.query.mode === query.mode.risk;
        }

        const isRiskMap = () => {
            return isRiskMode() || isWaterLevelMode();
        }

        const isNormalMode = () => {
            return !route.query.mode;
        }

        const isEvacuationMap = () => {
            return route.query.has === query.mode.evacuation;
        }

        const isEvacuationMode = () => {
            return route.query.mode === query.mode.evacuation;
        }

        const toggleBlueBanner = (whichButton) => {
            if (whichButton > blueBanner.length || whichButton < 0) {
                emergencyData.value = null;
                return;
            }

            blueBanner[whichButton].status = !blueBanner[whichButton].status;

            if (!blueBanner[CONGESTION_BUTTON].status || getQuery('water-level') != 3) {
                emergencyData.value = null;
                return;
            }

            emergencyData.value = {
                text: blueBanner[CONGESTION_BUTTON].text
            };
        }

        const registerListControl = () => {
            const mapBoxControl = mapBox.value.control;

            if (!mapBoxControl.listControl) return;
            
            mapBoxControl.listControl.registerEventOnClick(() => {
                if (listControlData.value) {
                    bubbleContent.value = listControlData.value;
                    bubblePopup.value.onOpen(true)
                    carouselPopup.value.onClose();

                    if (mapBoxControl.tsunamiControl) {
                        mapBoxControl.tsunamiControl.setActiveButton(true);
                    }
                } else {
                    mapBoxControl.listControl.setActiveButton(false);
                }
            });

            if (isRiskMap()) {
                mapBoxControl.listControl.setDisplayButton(false);
            }
        }

        const onCarouselSelect = (value) => {
            selectedMarker.value = value;
            mapBox.value.marker.setMarkerActive('shelter-' + value);
        }

        const initRiskButton = () => {
            if (isWaterLevelMode()) {
                floodButtonName.value = 'map.flood';
                tsunamiButtonName.value = 'map.climax';
            } else {
                floodButtonName.value = 'map.liquefaction';
                tsunamiButtonName.value = 'map.tsunami';
            }
        }

        const registerShelterControl = () => {
            const mapBoxControl = mapBox.value.control;
            if (!mapBoxControl.shelterControl) {
                return;
            }

            mapBoxControl.shelterControl.registerEventOnClick(() => {
                bubblePopup.value.onClose();
                
                if (!mapBox.value.marker.isShowMarkers()) {
                    createQueryIfNotExist('action', query.action.shelter);
                    carouselPopup.value.onOpen();
                } else {
                    removeQuery('action', query.action.shelter);
                    carouselPopup.value.onClose();
                }
                
                mapBox.value.marker.setMarkersDisplay(!mapBox.value.marker.isShowMarkers());
                mapBoxControl.shelterControl.setActiveButton(mapBox.value.marker.isShowMarkers());

                if (mapBoxControl.listControl) {
                    mapBoxControl.listControl.setActiveButton(false);
                }
            })
        
            shelter.init();

            if(checkIfQueryExisted(query.action.shelter)) {
                mapBoxControl.shelterControl.trigger();
            }
        }

        const registerTwoGroupControl = () => {
            const mapBoxControl = mapBox.value.control;

            if (!mapBoxControl.twoGroupControl) return;

            const buttonGroupEvent = (data, query, excludeQuery = []) => {
                bubbleContent.value = data;
                listControlData.value = bubbleContent.value;

                bubblePopup.value.onOpen();
                carouselPopup.value.onClose();

                createQueryIfNotExist('action', query, excludeQuery);
                
                if (mapBoxControl.listControl) {
                    mapBoxControl.listControl.setActiveButton(true);
                }
            }

            const tsunamiID = 0;
            const stormID = 0;
            const liquefactionID = 1;
            const floodID = 1;

            const buttons = [
                {
                    id: 'tsunami',
                    event: () => {
                        zone.toggleStormZone(true);
                        buttonGroupEvent(bubbleSample.tsunami, query.action.tsunami);
                    },
                },
                {
                    id: 'liquefaction',
                    event: () => {},
                }
            ];

            if (isEvacuationMode()) {
                mapBoxControl.twoGroupControl.setText(buttons[liquefactionID].id, t('map.liquefaction'));

                buttons[liquefactionID].event = () => {
                    zone.toggleLiquefactionZone(true);
                    riskPopup.updateRiskPopupText(t('map.risk_popup.liquefaction'));
                    buttonGroupEvent(bubbleSample.liquefaction, query.action.liquefaction, [query.action.tsunami]);
                }

                buttons[tsunamiID].event = () => {
                    zone.toggleTsunamiZone(true);
                    riskPopup.updateRiskPopupText(t('map.risk_popup.tsunami'));
                    buttonGroupEvent(bubbleSample.tsunami, query.action.tsunami, [query.action.liquefaction]);
                }
            } else {
                mapBoxControl.twoGroupControl.setText(buttons[tsunamiID].id, t('map.climax'));
                mapBoxControl.twoGroupControl.setText(buttons[floodID].id, t('map.flood'));

                mapBoxControl.twoGroupControl.updateIcon(buttons[floodID].id, 'flood.svg');

                buttons[stormID].event = () => {
                    zone.toggleStormZone(true);
                    riskPopup.updateRiskPopupText(t('map.risk_popup.storm'));
                    buttonGroupEvent(bubbleSample.storm, query.action.storm, [query.action.flood]);
                }

                buttons[floodID].event = () => {
                    zone.toggleFloodZone(true);
                    riskPopup.updateRiskPopupText(t('map.risk_popup.flood'));
                    buttonGroupEvent(bubbleSample.flood, query.action.flood, [query.action.storm]);
                }
            }

            for(const button of buttons) {
                mapBoxControl.twoGroupControl.registerButtonOnClick(
                    button.id,
                    button.event
                );
            }

            if(checkIfQueryExisted('action', query.action.flood)) {
                mapBoxControl.twoGroupControl.triggerButton('liquefaction');
            }

            if(checkIfQueryExisted('action', query.action.storm)) {
                mapBoxControl.twoGroupControl.triggerButton('tsunami');
            }

            if(checkIfQueryExisted('action', query.action.liquefaction)) {
                mapBoxControl.twoGroupControl.triggerButton('liquefaction');
            }

            if(checkIfQueryExisted('action', query.action.tsunami)) {
                mapBoxControl.twoGroupControl.triggerButton('tsunami');
            }
            
        }

        const registerThreeGroupControl = () => {
            const mapBoxControl = mapBox.value.control;
            if (!mapBoxControl.threeGroupControl) return;

            const shelterButtonId = 'shelter';
            const congestionButtonId = 'congestion';
            const waterLevelButtonId = 'waterlevel';

            mapBoxControl.threeGroupControl.registerButtonOnClick(shelterButtonId, () => {
                bubblePopup.value.onClose();
                
                if (!mapBox.value.marker.isShowMarkers()) {
                    createQueryIfNotExist('action', query.action.shelter);
                    carouselPopup.value.onOpen();
                } else {
                    carouselPopup.value.onClose();
                    removeQuery('action', query.action.shelter);
                }
                mapBox.value.marker.setMarkersDisplay(!mapBox.value.marker.isShowMarkers());
            });

            mapBoxControl.threeGroupControl.registerButtonOnClick(congestionButtonId, () => {

                bubblePopup.value.onClose();
                congestion.toggleCongestion(!congestion.isShowCongestion());

                toggleBlueBanner(CONGESTION_BUTTON);

                if (congestion.isShowCongestion()) {
                    createQueryIfNotExist('action', query.action.congestion);
                } else {
                    removeQuery('action', query.action.congestion);
                }
            });

            mapBoxControl.threeGroupControl.registerButtonOnClick(waterLevelButtonId, () => {
                bubblePopup.value.onClose();
                waterLevel.toggleWaterLevel(!waterLevel.isShowWaterLevel());

                toggleBlueBanner(WATER_LEVEL_BUTTON);

                if (waterLevel.isShowWaterLevel()) {
                    createQueryIfNotExist('action', query.action.waterLevel);
                } else {
                    removeQuery('action', query.action.waterLevel);
                }
            });

            shelter.init();
            congestion.init();
            waterLevel.init();
        }

        const registerTsunamiControl = () => {
            const mapBoxControl = mapBox.value.control;

            if (!mapBoxControl.tsunamiControl || !isRiskMap()) return;

            mapBoxControl.tsunamiControl.registerEventOnClick(() => {
                bubbleContent.value = bubbleSample.risk;
                listControlData.value = null;

                bubblePopup.value.onOpen();
                carouselPopup.value.onClose();
                mapBoxControl.listControl.setDisplayButton(true);
                mapBoxControl.listControl.setActiveButton(false);
                mapBoxControl.tsunamiControl.setActiveButton(true);

                createQueryIfNotExist('action', query.action.risk);
            });

            if(checkIfQueryExisted('action', query.action.risk)) { 
                mapBoxControl.tsunamiControl.trigger();
            }
        }

        const eventListener = () => {
            registerListControl();
            registerShelterControl();
            registerTwoGroupControl();
            registerThreeGroupControl();
            registerTsunamiControl();
            
            riskPopup.registerLocationEvent();

            closeBubbleEvent.value = () => {
                const mapBoxControl = mapBox.value.control;
                if (mapBoxControl.listControl) {
                    mapBoxControl.listControl.setActiveButton(false);
                }
            }
        };

        const initZone = () => {
            zone.init(mapBox.value);
            
            if (isRiskMode()) {
                zone.initLiquefactionZone();
                zone.initTsunamiZone();
                return;
            }

            if (isEvacuationMode()) {
                zone.initLiquefactionZone();
                zone.initTsunamiZone();
                return;
            }

            if (isWaterLevelMode() || isNormalMode()) {
                zone.initStormZone();
                zone.initFloodZone();
                return;
            }
        }

        setTitle(t('map.harzard_map_tag'));
        
        onMounted(() => {
            initZone();
            initRiskButton();
            eventListener();
        });

        return {
            isShowLoader: computed(() => store.getters["isShowLoader"]),
            zone: computed(() => zone),
            floodImageSrc: computed(() => isWaterLevelMode() ? require('@assets/img/icons/flood.svg') : require('@assets/img/icons/liquefaction.svg')),
            mapBox,
            bubblePopup,
            bubbleContent,
            carouselPopup,
            carousel,
            emergencyData,
            selectedMarker,
            closeBubbleEvent,
            floodButtonName,
            tsunamiButtonName,
            riskButtonActive,
            onQuestionClick,
            onBackClick,
            onTsunamiClick,
            onFloodClick,
            onCarouselSelect,
            isEvacuationMap,
            isEvacuationMode,
            isRiskMap,
            updateSelectedMarker,
            updateCarousel,
            updateBubble,
        };
    },
};
</script>
<style scoped>
.icon-questionmark {
    margin-left: 6px;
    cursor: pointer;
}

.icon-back-arrow {
    margin-right: 16px;
    cursor: pointer;
}
</style>