const waterLevel = [
    {
        id: "river-risk-1",
        coordinates: [
            [139.761134422383, 35.650769470360174],
            [139.76079606403908, 35.650856241853575],
            [139.760821, 35.650961],
            [139.76066370559928, 35.65098535090736],
            [139.7609359767984, 35.651866780891794],
            [139.759476, 35.652418],
            [139.756357, 35.652831],
            [139.756478, 35.653067],
            [139.759803, 35.652900],
            [139.7607592790531, 35.65250386547879],
            [139.7608179233552, 35.65250619053823],
            [139.76098382548219, 35.652455656423015],
            [139.76134740735546, 35.652517532676114],
            [139.7618997113683, 35.652339939867176],
            [139.76183854007644, 35.6522032485946],
            [139.76249001433456, 35.651942291879216]
        ],
        max_water_level: 50,
        popup: {
            text: "河川氾濫",
            center: [139.76076054461234, 35.65250876814725]
        },
        emergency: {
            text: "map.emergency.river",
        },
        bubble: [
            {
                id: 3,
                title: "map.bubble.waterLevel.title",
                subTitle: "map.bubble.waterLevel.content",
                waterlevel: 3,
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/water_level_small.svg"),
            },
            {
                id: 4,
                title: "map.bubble.waterLevel.title",
                subTitle: "map.bubble.waterLevel.content",
                waterlevel: 1,
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/water_level_medium.svg"),
            },
            {
                id: 5,
                title: "map.bubble.waterLevel.title_2",
                subTitle: "map.bubble.waterLevel.content",
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/water_level_high.svg"),
            }
        ]
    },
    {
        id: "manhole-risk-1",
        coordinates: [
            [139.76074345774165, 35.65573418305252]
        ],
        max_water_level: 50,
        popup: {
            text: "マンホール冠水",
            center: [139.76074345774165, 35.65573418305252]
        },
        emergency: {
            text: "map.emergency.manhole",
        },
        bubble: [
            {
                id: 3,
                title: "map.bubble.manholeLevel.title",
                subTitle: "map.bubble.manholeLevel.content",
                waterlevel: 2,
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/manhole_level_low.svg"),
            },
            {
                id: 4,
                title: "map.bubble.manholeLevel.title",
                subTitle: "map.bubble.manholeLevel.content",
                waterlevel: 2,
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/manhole_level_low.svg"),
            },
            {
                id: 5,
                title: "map.bubble.manholeLevel.title_2",
                subTitle: "map.bubble.manholeLevel.content",
                waterlevel: 0.1,
                class: "bubble-container--fluid",
                size: "lg",
                imageUrl: require("@/assets/img/manhole_level_high.svg"),
            }
        ]
    },
]

export default waterLevel;

