

import { ref, getCurrentInstance } from "vue";
import { PULL, GET_RIVER_STATUS_DATA } from "@/store/modules/riverStatus";
import store from "@/store/index";
import waterlevelData from "@/constants/waterLevelData";
import query from "@/constants/query";
import useQuery from "@/composables/dom/useQuery";
import { useI18n } from "vue-i18n";

export default function waterLevel() {
    const instance = getCurrentInstance().proxy;
    const { checkIfQueryExisted, getQuery } = useQuery();
    const { t } = useI18n();

    let waterlevels = ref([]);
    let showWaterLevel = ref(false);

    const getRiverStatusData = () => {
        return store.getters[GET_RIVER_STATUS_DATA];
    }

    const initWaterLevel = () => {
        const mapBox = instance.mapBox;

        for (const waterlevel of waterlevelData) {
            let bubbleLevel = waterlevel.bubble[0];

            waterlevel.bubble.filter((bubble) => {
                if (bubble.id !== (Number(getQuery('water-level')) || 5)) {
                    return true;
                }
                bubbleLevel = bubble;
            })

            waterlevel.bubble = bubbleLevel;
            waterlevel.bubble.title = getTitle(waterlevel.bubble);

            mapBox.line.addLine(waterlevel);
            mapBox.line.registerOnPopupClick(waterlevel.id, () => {
                instance.updateBubble(waterlevel.bubble);
                instance.carouselPopup.onClose();
                instance.bubblePopup.onOpen();
            })

            waterlevels.value.push(waterlevel);
        }
    }

    const getTitle = (bubble) => {
        if (!bubble.waterlevel) {
            return "<span class='color-danger'>" + t(bubble.title) + "</span>";
        }

        return t(bubble.title, {
            level:
                "<span class='color-danger'>" +
                    (bubble.waterlevel < 1
                        ? Number(bubble.waterlevel * 100)
                        : bubble.waterlevel) +
                "</span>",
            format: bubble.waterlevel < 1 ? "cm" : "m",
        });
    }

    const checkWaterLevel = (waterLevel, validateData) => {
        return waterLevel.id.includes('river-risk-') && 
            (validateData.water_level < waterLevel.max_water_level || 
            ('river-risk-' + validateData.river_status_id) !== waterLevel.id);
    }

    const toggleWaterLevel = (toggle = false) => {
        const mapBox = instance.mapBox;
        const riverStatusData = getRiverStatusData();

        for (const waterlevel of waterlevels.value) {
            if (riverStatusData.some(e => checkWaterLevel(waterlevel, e) )) {
                toggle = false;
            }

            mapBox.line.setLineDisplay(waterlevel.id, toggle);
        }

        showWaterLevel.value = toggle;
    }

    const updateWaterLevel = () => {
        const mapBox = instance.mapBox;
        const WATERLEVEL_BUTTON_INDEX = 'waterlevel';

        if(!checkIfQueryExisted('action', query.action.waterLevel)) {
            return;
        }
        
        if (mapBox.control.threeGroupControl) {
            mapBox.control.threeGroupControl.triggerButton(WATERLEVEL_BUTTON_INDEX);
        }
    }

    const init = () => {
        initWaterLevel();
        store.dispatch(PULL).then(() => {
            updateWaterLevel();
        });
    }

    const isShowWaterLevel = () => {
        return showWaterLevel.value;
    }

    return {
        init,
        toggleWaterLevel,
        isShowWaterLevel
    };
}