import store from "@/store/index";
import { getCurrentInstance, ref } from "vue";
import { PULL, GET_SHELTER_DATA } from "@/store/modules/shelter";
import query from "@/constants/query";
import useQuery from "@/composables/dom/useQuery";

export default function shelter() {
    const instance = getCurrentInstance().proxy;
    const { replaceQuery, hasQuery, getQuery, checkIfQueryExisted } = useQuery();
    const shelters = ref([]);

    const getShelters = () => {
        return store.getters[GET_SHELTER_DATA];
    }

    const getShelterType = (tags) => {
        if (tags.some(e => e.name === "津波避難ビル")) {
            return 'marker-shelter';
        }

        return 'marker-safe';
    }

    const initShelterMarkers = () => {
        const LAT_INDEX = 0;
        const LONG_INDEX = 1;
        const SHELTER_BUTTON_INDEX = 'shelter';

        const mapBox = instance.mapBox;

        shelters.value = getShelters();
        instance.updateCarousel(shelters.value);

        for (let i = 0; i < shelters.value.length; i++) {
            const shelter = shelters.value[i];
            const markerID = 'shelter-' + shelter.shelter_id;
            const coordinate = shelter.coordinate.coordinates;

            mapBox.marker.addMarker(markerID, coordinate[LAT_INDEX], coordinate[LONG_INDEX], getShelterType(shelter.tags));

            mapBox.marker.registerMarkerClick(markerID, () => {
                if (instance.selectedMarker === shelter.shelter_id) {
                    instance.carouselPopup.onSelect(shelter.shelter_id);
                } else {
                    instance.updateSelectedMarker(shelter.shelter_id);
                }
                
                mapBox.marker.setMarkerActive('shelter-' + instance.selectedMarker);

                if (instance.isEvacuationMap() || instance.isEvacuationMode() || instance.isRiskMap()) {
                    instance.bubblePopup.onClose();
                }

                replaceQuery({
                    shelter_id: shelter.shelter_id
                });
            });
        }

        mapBox.marker.setMarkersDisplay(false);

        if(!checkIfQueryExisted('action', query.action.shelter)) {
            return;
        }
        
        if (mapBox.control.threeGroupControl) {
            mapBox.control.threeGroupControl.triggerButton(SHELTER_BUTTON_INDEX);
        }

        if (mapBox.control.shelterControl) {
            mapBox.control.shelterControl.trigger();
        }
        
        if (
            !hasQuery('shelter_id') || 
            !shelters.value.some(e => e.shelter_id == getQuery("shelter_id"))
        ) {
            return;
        }

        instance.updateSelectedMarker(getQuery("shelter_id"));
        mapBox.marker.setMarkerActive('shelter-' + instance.selectedMarker);
    }

    const init = () => {
        store.dispatch(PULL).then(() => {
            initShelterMarkers();
        });
    }

    return {
        getShelters,
        init,
    };
}