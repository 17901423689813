

import { ref, getCurrentInstance } from "vue";
import congestionData from "@/constants/congestionData";
import { PULL, GET_ROAD_DATA } from "@/store/modules/roadData";
import store from "@/store/index";
import query from "@/constants/query";
import useQuery from "@/composables/dom/useQuery";

export default function congestion() {
    const instance = getCurrentInstance().proxy;
    const { checkIfQueryExisted, getQuery } = useQuery();

    let congestions = ref([]);
    let showCongestion = ref(false);

    const getRoadData = () => {
        return store.getters[GET_ROAD_DATA];
    }

    const isWarningLevel3 = () => {
        return getQuery('water-level') == 3
    }

    const initCongestion = () => {
        const mapBox = instance.mapBox;

        if (isWarningLevel3()) {
            return;
        }

        for (const congestion of congestionData) {
            mapBox.line.addLine(congestion);

            mapBox.line.registerOnPopupClick(congestion.id, () => {
                instance.updateBubble(congestion.bubble);
                instance.carouselPopup.onClose();
                instance.bubblePopup.onOpen();
            })

            congestions.value.push(congestion);
        }
    }

    const toggleCongestion = (toggle = false) => {
        const mapBox = instance.mapBox;
        const roadData = getRoadData();

        for (const congestion of congestions.value) {
            if (roadData.some(e => e.in < 100 || e.surveillance_camera_id !== congestion.id)) {
                toggle = false;
            }

            mapBox.line.setLineDisplay(congestion.id, toggle);
        }
        
        showCongestion.value = toggle;
    }

    const updateCongestion = () => {
        const mapBox = instance.mapBox;
        const CONGESTION_BUTTON_INDEX = 'congestion';

        if(!checkIfQueryExisted('action', query.action.congestion)) {
            return;
        }
        
        if (mapBox.control.threeGroupControl) {
            mapBox.control.threeGroupControl.triggerButton(CONGESTION_BUTTON_INDEX);
        }
    }

    const init = () => {
        initCongestion();
        store.dispatch(PULL).then(() => {
            updateCongestion()
        });
        
    }

    const isShowCongestion = () => {
        return showCongestion.value;
    }

    return {
        init,
        toggleCongestion,
        isShowCongestion,
        getRoadData
    };
}